import React from 'react'
import stnewslogo1 from '../assets/stnewslogo1.jpg';

const topnav = () => {
  return (
          <div className='bg-black flex items-center justify-center'>
          <img src={stnewslogo1} className='w-24 p-4 md:items-center h-auto' alt='logo' />
          <h1 className='text-white text-center text-3xl font-bold'>Studytap News</h1>
        </div>
  )
}

export default topnav