import React from 'react'
import stnewslogo1 from '../assets/stnewslogo1.jpg';
import { Link } from 'react-router-dom';
const footer = () => {
  return (
          <footer className="bg-black py-6">
          <div className="container mx-auto px-4">
            <div className="flex items-center justify-center">
              <img src={stnewslogo1} className="w-24 p-4 h-auto" alt="logo" />
              <h1 className="text-white text-center text-3xl font-bold">Studytap News</h1>
            </div>
            <div className="flex text-center justify-center">
            <Link to="/privacy" className="text-white">Privacy Policy</Link>
              <div className="mx-3 border-r border-white h-5"></div>
              {/* <h1 className="text-white">Terms and Conditions</h1> */}
              <Link to="/terms" className="text-white">Terms & conditions</Link>
            </div>

            {/* <div className="flex items-center justify-center mt-4">
              <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                <img src={facebook} className="w-8 h-8 mx-2" alt="Facebook" />
              </a>
              <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
                <img src={twitter} className="w-8 h-8 mx-2" alt="Twitter" />
              </a>
              <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                <img src={insta} className="w-8 h-8 mx-2" alt="Instagram" />
              </a>
              <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                <img src={linkdin} className="w-8 h-8 mx-2" alt="LinkedIn" />
              </a>
            </div>  */}
             <div className="mt-1 md:mt-8 border-t border-gray-500 pt-1 md:pt-8 flex flex-col items-center">
            </div>
          </div>
          <div className="mt-1 md:mt-1 text-center">
            <p className="text-sm text-white">&copy;  Copyright 2021 Shree gajanana enterprises LLP, Hyderabad, Telangana - All Rights Reserved.</p>
          </div>
        </footer> 
  )
}

export default footer