

import React, { useState, useEffect } from "react";
import Topnav from './components/topnav';
import Footer from './components/footer';
import Privacy from './pages/privacy';
import Terms from './pages/terms';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import app from './assets/app.png'; // Importing the image
import apple from './assets/apple.jpg';
import google from './assets/google.jpg';

function App() {
  const [data, setData] = useState([]);

  const fetchInfo = () => {
    return fetch('https://news.shreegajanana.com/api/news/list_news/')
      .then((res) => res.json())
      .then((d) => setData(d.slice(8, 12).map(item => ({
        ...item,
        description: item.description.replace(/<\/?[^>]+(>|$)/g, "")
      }))))
      .catch((error) => console.error('Error fetching data:', error));
  }

  useEffect(() => {
    fetchInfo();
  }, []);

  return (
    <BrowserRouter>
      <Topnav />
      <Routes>
        <Route path="/privacy" element={<PrivacyLayout />} />
        <Route path="/terms" element={<TermsLayout />} />
        <Route path="/" element={<MainLayout data={data} />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

const MainLayout = ({ data }) => (
  <>
    <Hero />
    <News data={data} />
    <Contact />
  </>
);

const PrivacyLayout = () => (
  <>
    <Privacy />
  </>
);
const TermsLayout = () => (
  <>
    <Terms />
  </>
);

const Hero = () => (
  <div className='sm:flex-directon lg:flex items-center justify-center'>
     <div className="p-4 h-auto animate-pulse" >
      <img src={app} className='p-4 h-auto' alt='app' />
    </div>
    <div className='flex-1'>
      <p className="text-2xl font-bold  p-10">Welcome StudyTap News</p>
      <p className="text-justify pl-10 pr-5 pb-2 pt-2 ">StudyTap News is your go-to platform for all educational news and updates. Whether you're a student preparing for competitive exams like UPSC, JEE, or seeking updates from prestigious universities like JNTUH and JNTUK, StudyTap News has got you covered. Our website specializes in delivering timely and relevant educational news, focusing solely on topics related to academics, career opportunities in jobs, and advancements in the education sector.<br /><br />
        Stay informed about the latest announcements regarding exam dates, syllabus changes, result declarations, and job opportunities tailored specifically for students and educators. With StudyTap News, you'll never miss out on crucial information that could impact your educational journey.<br /><br />
        Our user-friendly interface makes it easy to navigate through different categories such as exam updates, university news, job notifications, and educational articles. Whether you're a student, teacher, or educational enthusiast, StudyTap News ensures that you stay ahead with accurate and insightful content.<br /><br />
        Join our growing commun ity of learners and educators who rely on StudyTap News for their daily dose of educational news and stay empowered to make informed decisions about your academic and professional pursuits.<br />
      </p>
    </div>
   
  </div>
);

const News = ({ data }) => (
  <div className="container mx-auto px-4">
    <h1 className="text-purple-900 justify-center text-lg font-semibold mt-3 mb-3 text-center"> Latest News</h1>
    {data.map((dataObj, index) => {
      return (
        <div
          key={index}
          className="news-item border border-black rounded-lg mb-4 flex flex-col sm:flex-row"
        >
          <img src={dataObj.media_url} alt="Media" className="news-image w-full sm:w-1/5 h-auto mb-4 sm:mb-0 sm:mr-4" />
          <div className="news-content flex-1">
            <p className="news-title text-lg font-bold text-black">{dataObj.title}</p>
            <p className="news-title text-sm  text-gray">{dataObj.description}</p>
            <a href={"https://play.google.com/store/apps/details?id=com.edstudytapnews&pcampaignid=web_share"} target="_blank" rel="noopener noreferrer" className="news-link text-blue-600">View Details</a>
          </div>
        </div>
      );
    })}
  </div>
);

const Contact = () => (
  <div>
  <div className="load-more-container text-center">
 <a href="https://play.google.com/store/apps/details?id=com.edstudytapnews&pcampaignid=web_share" ><button  className="load-more-button bg-blue-500 text-white px-4 py-2  mb-3 rounded-lg shadow-md hover:bg-blue-600 focus:outline-none">Load More</button>
 </a>
</div>
<div>
  <h1 className='text-center'>Download the easist way to stay informed</h1>
 <div className=' flex flex-row items-center justify-center mb-3 '>
   <a href="https://play.google.com/store/apps/details?id=com.edstudytapnews&pcampaignid=web_share" target="_blank" rel="noopener noreferrer">
   <img src={apple} alt="Google Play Store" width="200" />
      </a>
    


    <a href="https://play.google.com/store/apps/details?id=com.edstudytapnews&pcampaignid=web_share" target="_blank" rel="noopener noreferrer">
    <img src={google} alt="Apple App Store" width="200" />
    </a>
     
 
 </div>
</div>
<p className="text-lg font-semibold mt-8 text-purple-900 text-center">Contact Us</p>
<ul className="mb-3 ml-6">
 <li className="mb-1 text-center">Email: contact.studytapnews@gmail.com</li>
 <li className='text-center'>Phone: +91 7674946379</li>
</ul>
</div>
);

export default App;
